import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import Nav from "./Nav";

import styles from "./index.module.scss";

const VISIBLE_ANIMATION_SCROLL_TOP = 300;

const Header = () => {
  const [scrollTop, setScrollTop] = useState<number>(0);

  // 监听页面滚动高度 For导航UI
  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = window.scrollY || document.documentElement.scrollTop;
      setScrollTop(scrollHeight);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={classNames(styles["wrap"], {
        [styles["hide"]]:
          scrollTop < VISIBLE_ANIMATION_SCROLL_TOP && scrollTop > 0,
        [styles["visible"]]: scrollTop > VISIBLE_ANIMATION_SCROLL_TOP,
      })}
    >
      <div className={styles["content"]}>
        <Link to="/">
          <img src="/images/logo--white-text.svg" alt="Logo" />
        </Link>
        <Nav />
      </div>
    </div>
  );
};

export default Header;
