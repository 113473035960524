// src/components/VerifyEmail/VerifyEmail.tsx
import React, { useState, useEffect } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss"; // Create this CSS module

const VerifyEmail: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (!user) return;

    const checkEmailVerification = async () => {
      await user.reload(); // Refresh user data
      if (user.emailVerified) {
        navigate("/auth/profile");
      }
    };

    checkEmailVerification();
  }, [user, navigate]);

  const handleResendVerification = async () => {
    if (!user) {
      setError("User not found. Please login again.");
      // redirect to the login page after 3 seconds
      setTimeout(() => {
        navigate("/auth/login");
      }, 3000);
      return;
    }

    if (user.emailVerified) {
      setMessage("Email is already verified.");
      setTimeout(() => {
        navigate("/auth/profile");
      }, 3000);
      return;
    }

    setLoading(true);
    setMessage("");
    setError("");

    try {
      await sendEmailVerification(user);
      setMessage("Verification email has been sent. Please check your inbox.");
    } catch (error: any) {
      setError("Too many requests, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles["verify-email-container"]}>
      <h1>Verify your email</h1>
      <p>
        A verification email has been sent to your email address. Please check
        your inbox and click the link to verify your email.
      </p>
      {message && <div className={styles["message"]}>{message}</div>}
      {error && <div className={styles["error"]}>{error}</div>}
      <button
        onClick={handleResendVerification}
        disabled={loading}
        className={styles["resend-button"]}
      >
        {loading ? "Resending..." : "Resend Verification Email"}
      </button>
    </div>
  );
};

export default VerifyEmail;
