import Button from "../Button";
import Animation from "./Animation";

import styles from "./index.module.scss";

const Banner = () => {
  const handleButtonClick = () => {
    // Scroll to an element with the ID 'overview'
    const targetArea = document.getElementById("overview");
    if (targetArea) {
      window.scrollTo({
        top: targetArea.offsetTop,
        behavior: "smooth", // Smooth scroll
      });
    }
  };

  return (
    <div className={styles["wrap"]}>
      <div className={styles["banner"]}>
        <Animation />
        <div className={styles["content"]}>
          <h1 className={styles["title"]}>
            Life's Illusion: A Journey Through Time With FakeLife.AI
          </h1>
          <div className={styles.container}>
            <div className={styles.item}>Unearth The Past</div>
            <div className={styles["item-list"]}>
              <div className={styles.item}>Capture The Present</div>
              <div className={styles.item}>Design The Future</div>
            </div>
          </div>
        </div>
        <div className={styles["button"]}>
          <Button onClick={handleButtonClick}>Explore Now</Button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
