export const RoutesList = [
  { label: "Home", router: "/" },
  { label: "About", router: "/about" },
  { label: "Services", router: "/services" },
  // {
  //   label: "Pages",
  //   menu: [
  //     {
  //       key: "privacy_policy",
  //       label: "Privacy Policy",
  //     },
  //     {
  //       key: "faq",
  //       label: "FAQ",
  //     },
  //   ],
  // },
  { label: "FAQ", router: "/faq" },
  { label: "Contact", router: "/contact" },
];

export const SHARE_METHOD_LIST = [
  {
    key: "facebook",
    icon: "https://dashing-licorice-9328bc.netlify.app/icons/facebook-f-brands-solid.svg",
  },
  {
    key: "x-twitter",
    icon: "https://dashing-licorice-9328bc.netlify.app/icons/x-twitter-brands-solid.svg",
  },
  {
    key: "youtube",
    icon: "https://dashing-licorice-9328bc.netlify.app/icons/youtube-brands-solid.svg",
  },
];

export const SERVICES = [
  "Daily Advice",
  "Annual Insights",
  "Future Predictions",
  "Personal Traits",
  "Compatibility Analysis",
];

export const LINKS = ["About", "Services", "FAQ", "Contact", "Privacy Policy"];

// export const TOUCH = [
//   {
//     icon: "icon-home",
//     content: ["level2, 171 La Trobe St, Melbourne", "VIC 3000, Australia"],
//   },
//   {
//     icon: "icon-aite",
//     content: ["contact@fakelife.ai", ""],
//   },
//   {
//     icon: "icon-dianhua",
//     content: ["+1800 326 3264", "+1800 326 3234"],
//   },
// ];

export const COMPONENTS_THEME = {
  components: {
    Dropdown: {
      colorBgElevated: "#333333",
      controlItemBgHover: "#50aceb",
      colorText: "#fff",
      borderRadiusLG: 2,
      fontSizeSM: 18,
      fontSize: 18,
      algorithm: true, // 启用算法
    },
  },
};
