import { Button } from "antd";
import { ReactNode } from "react";
import classNames from "classnames";

import styles from "./index.module.scss";

/**
 * 封装Button组件
 */

interface IProps {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
  type?: "primary" | "dashed" | "link" | "text" | "default";
  htmlType?: "button" | "submit";
}

export default function ButtonComp(props: IProps) {
  const {
    onClick,
    children,
    className,
    type = "default",
    htmlType = "button",
  } = props;
  return (
    <Button
      className={classNames(styles["btn-comp"], className)}
      onClick={onClick}
      type={type}
      htmlType={htmlType}
    >
      {children}
    </Button>
  );
}
