import { Image } from 'antd';
import styles from './index.module.scss';

const Download = () => {
    return (
        <div className={styles['wrap']}>
            <h1>
                Download Our <span>Mobile App</span>
            </h1>
            <p>Coming Soon...</p>

            <div className={styles['download']}>
                <Image
                    className={styles['pic']}
                    src="/images/download-app-store.png"
                    preview={false}
                />
                <Image
                    className={styles['pic']}
                    src="/images/download-google-play.png"
                    preview={false}
                />
            </div>
        </div>
    );
};

export default Download;
