import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import styles from "./index.module.scss";

const ProfileMenu = ({ show }: { show: boolean }) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleLogout = async () => {
    await signOut();
    navigate("/auth/login");
  };

  return (
    <div className={`${styles["profile-menu"]} ${show ? styles["show"] : ""}`}>
      <div
        className={styles["profile-menu-item"]}
        onClick={() => navigate("/auth/my-plan")}
      >
        <span className={styles["profile-menu-item-text"]}>My Plan</span>
      </div>
      <div
        className={styles["profile-menu-item"]}
        onClick={() => navigate("/auth/profile")}
      >
        <span className={styles["profile-menu-item-text"]}>Profile</span>
      </div>
      <div className={styles["profile-menu-item"]} onClick={handleLogout}>
        <span className={styles["profile-menu-item-text"]}>Log Out</span>
      </div>
    </div>
  );
};

export default ProfileMenu;
