// src/components/EmailVerifiedRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import ShimmerWave from "./Loading";

const EmailVerifiedRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <ShimmerWave />;
  }

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  if (user.emailVerified) {
    return <Navigate to="/auth/profile" />;
  }

  return children;
};

export default EmailVerifiedRoute;
