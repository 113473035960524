import React from "react";
import styles from "./index.module.scss";

const MyPlanPage: React.FC = () => {
  return (
    <div className={styles.aboutPage}>
      <section className={styles.heroSection}>
        <div className={styles.heroContent}>
          <h1>My Plan</h1>
          <p>View and manage your current plan.</p>
        </div>
      </section>
    </div>
  );
};

export default MyPlanPage;
