import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAuth, confirmPasswordReset, signOut } from "firebase/auth";
import styles from "./index.module.scss";

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = getAuth();

  const handleResetPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(""); // Clear previous error messages
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (password.length < 6) {
      setError("Password should be at least 6 characters long.");
      return;
    }

    const oobCode = searchParams.get("oobCode");
    if (!oobCode) {
      setError("Invalid or missing reset code.");
      return;
    }

    try {
      setIsSubmitting(true);
      await confirmPasswordReset(auth, oobCode, password);
      setMessage(
        "Password has been reset successfully! Redirecting to login..."
      );
      setError("");
      // Sign out the user after resetting the password
      await signOut(auth);
      setTimeout(() => navigate("/auth/login"), 3000); // 3 seconds delay before redirecting to login
    } catch (error: any) {
      setIsSubmitting(false);
      setError("An error occurred. Please try again.");
      setMessage("");
    }
  };

  const handleNavigateToLogin = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate("/auth/login");
  };

  return (
    <div className={styles["wrap"]}>
      <h1>Reset your password</h1>
      <div className={styles["reset-password-page"]}>
        <form onSubmit={handleResetPassword}>
          <input
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {error && <div className={styles["error"]}>{error}</div>}
          {message && <div className={styles["message"]}>{message}</div>}
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
        <div className={styles["switch-link"]}>
          <a href="/auth/login" onClick={handleNavigateToLogin}>
            Back to Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
