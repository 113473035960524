import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { RoutesList } from "../../../../constant";
import ProfileMenu from "../ProfileMenu";
import styles from "./index.module.scss";

const NavPc = () => {
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);
  const profileIconRef = useRef<HTMLDivElement>(null);

  const handleProfileClick = () => {
    setShowProfileMenu((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      profileIconRef.current &&
      !profileIconRef.current.contains(event.target as Node)
    ) {
      setShowProfileMenu(false);
    }
  };

  useEffect(() => {
    if (showProfileMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showProfileMenu]);

  if (loading) {
    return null;
  }

  return (
    <div className={styles["nav-list-wrap"]}>
      {/* Navigation */}
      <nav className={styles["nav-list"]}>
        {RoutesList.map((item, index) => (
          <div key={index}>
            <Link to={item.router}>
              <div className={styles["nav-item"]}>{item.label}</div>
            </Link>
          </div>
        ))}
      </nav>

      {/* Login / Profile / Signup */}
      <div className={styles["login-wrap"]}>
        {user ? (
          <div
            className={styles["profile-icon"]}
            onClick={handleProfileClick}
            ref={profileIconRef}
          >
            {user.photoURL ? (
              <img
                src={user.photoURL}
                alt="Profile"
                className={`${styles["profile-placeholder"]} ${styles["profile-image"]}`}
              />
            ) : (
              <img
                src="/images/profile-white.svg"
                alt="Profile"
                className={styles["profile-image"]}
              />
            )}
            <ProfileMenu show={showProfileMenu} />
          </div>
        ) : (
          <>
            <button
              className={styles["nav-signup-button"]}
              onClick={() => navigate("/auth/signup")}
            >
              Sign Up
            </button>
            <button
              className={styles["nav-login-button"]}
              onClick={() => navigate("/auth/login")}
            >
              Log In
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default NavPc;
