import { GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

export const uiConfig = {
  signInFlow: "popup", // or 'redirect'
  signInSuccessUrl: "/",
  signInOptions: [
    // {
    //   provider: EmailAuthProvider.PROVIDER_ID,
    //   signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    //   requireDisplayName: false,
    // },
    GoogleAuthProvider.PROVIDER_ID,
    //FacebookAuthProvider.PROVIDER_ID,
  ],
  tosUrl: "<your-tos-url>",
  privacyPolicyUrl: "<your-privacy-policy-url>",
};
