import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  applyActionCode,
  checkActionCode,
  verifyPasswordResetCode,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../../config/firebase";
import styles from "./index.module.scss";

const ActionHandler: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    const mode = searchParams.get("mode");
    const oobCode = searchParams.get("oobCode");

    if (!mode || !oobCode) {
      setMessage("Invalid or missing action code.");
      setTimeout(() => navigate("/auth/login"), 3000);
      return;
    }

    const handleAction = async () => {
      try {
        switch (mode) {
          case "resetPassword":
            const valid = await verifyPasswordResetCode(auth, oobCode);
            if (valid) {
              navigate(`/auth/reset-password?oobCode=${oobCode}`);
            } else {
              setMessage("Invalid or expired reset code.");
              setTimeout(() => navigate("/auth/login"), 3000);
            }
            break;
          case "verifyEmail":
            await applyActionCode(auth, oobCode);
            setMessage("Email verified successfully!");

            // Check if the user is logged in
            onAuthStateChanged(auth, (user) => {
              if (user) {
                setTimeout(() => navigate("/auth/profile"), 3000);
              } else {
                setTimeout(() => navigate("/auth/login"), 3000);
              }
            });
            break;
          case "recoverEmail":
            const info = await checkActionCode(auth, oobCode);
            // Handle email recovery logic
            setMessage("Email recovery successful! Redirecting to login...");
            setTimeout(() => navigate("/auth/login"), 3000);
            break;
          default:
            setMessage("Unknown action.");
            setTimeout(() => navigate("/auth/login"), 3000);
            break;
        }
      } catch (error: any) {
        switch (error.code) {
          case "auth/invalid-action-code":
            setMessage(
              "The link is invalid. This can happen if the link is expired or has already been used."
            );
            setTimeout(() => navigate("/auth/login"), 3000);
            break;
          case "auth/expired-action-code":
            setMessage("The link has expired. Please request a new one.");
            setTimeout(() => navigate("/auth/login"), 3000);
            break;
          case "auth/user-disabled":
            setMessage(
              "The user account has been disabled. Please contact our support for help"
            );
            setTimeout(() => navigate("/auth/login"), 3000);
            break;
          case "auth/user-not-found":
            setMessage("The user account doesn't exist.");
            setTimeout(() => navigate("/auth/login"), 3000);
            break;
          default:
            setMessage("An error occurred. Please try again.");
            setTimeout(() => navigate("/auth/login"), 3000);
            break;
        }
      }
    };

    handleAction();
  }, [auth, navigate, searchParams]);

  return (
    <div className={styles["action-handler"]}>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ActionHandler;
