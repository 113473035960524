import { useNavigate } from "react-router-dom";
import { Image } from "antd";

import Button from "../Button";

import styles from "./index.module.scss";

const Introduce = () => {
  const navigate = useNavigate();
  const navigateToAbout = () => {
    navigate("/about");
  };
  return (
    <div className={styles["wrap"]}>
      <div className={styles["header"]}>
        <h1 className={styles["title"]}>
          About <span className={styles["name"]}>FakeLife.AI</span>
        </h1>
        <p>
          Where data meets destiny, FakeLife.AI empowers you with AI-driven
          insights to navigate life's myriad paths.
        </p>
      </div>

      <div className={styles["main"]}>
        <Image
          className={styles["pic"]}
          src="/images/data-analytics.jpg"
          preview={false}
        />
        <div>
          <h4>Empowering Decisions With AI</h4>
          <p>
            Our platform leverages state-of-the-art AI to interpret life’s
            complexities through the lens of data. We analyze behavioral
            patterns, historical events, and personal milestones to offer you a
            clear vision of possible futures.
          </p>
          <p>
            Stripping away the veil of uncertainty, FakeLife.AI delivers
            personalized narratives grounded in statistical probabilities, not
            predetermined destinies. By understanding the probabilistic nature
            of life, we equip you with the confidence to make informed choices.
          </p>
          <Button onClick={navigateToAbout}>Learn More</Button>
        </div>
      </div>
    </div>
  );
};

export default Introduce;
