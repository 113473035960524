import styles from "./index.module.scss";

const Animation = () => {
  return (
    <>
      <div className={styles["starfield"]}>
        <span />
        <span />
        <span />
        <span />
      </div>
      {/* <div className={styles["ast_waves"]}>
        <div className={styles["ast_wave"]} />
        <div className={styles["ast_wave"]} />
        <div className={styles["ast_wave"]} />
      </div> */}
      <div className={styles["ast_waves2"]}>
        <div className={styles["ast_wave"]} />
        <div className={styles["ast_wave"]} />
        <div className={styles["ast_wave"]} />
      </div>
      {/* <div className={styles["ast_waves3"]}>
        <div className={styles["ast_wave"]} />
        <div className={styles["ast_wave"]} />
        <div className={styles["ast_wave"]} />
      </div> */}
    </>
  );
};

export default Animation;
