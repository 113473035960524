// src/components/NotFound.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={styles["wrap"]}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <button onClick={() => navigate("")}>Go back to the home page</button>
    </div>
  );
};

export default NotFound;
