import { useState, FormEvent } from "react";
import DatePickerComponent from "../../DateTimePicker/DatePicker";
import TimePickerComponent from "../../DateTimePicker/TimePicker";
import { Select, message } from "antd";
import dayjs from "dayjs";
import Button from "../../Button";
import styles from "./index.module.scss";

type LifeOverviewData = {
  result: LifeOverviewResult;
};

type LifeOverviewResult = {
  priority1: string;
  priority2: string[];
};

const LifeOverviewForm = () => {
  const [dob, setDob] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [result, setResult] = useState<LifeOverviewResult | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // Manage the visibility of the form and button
  const [formVisible, setFormVisible] = useState<boolean>(true);
  const cloudFunctionUrl =
    "https://australia-southeast1-chronoscape-feb6d.cloudfunctions.net/processLifeOverview";

  const isValidDate = (dateString: string): boolean => {
    const date = dayjs(dateString);
    if (!date.isValid()) return false; // Validates basic format

    const year = date.year();
    const month = date.month() + 1; // dayjs months are 0 indexed
    // const day = date.date();

    // Validate year range
    if (year < 1901 || year > 2099) {
      return false;
    }

    // Validate month
    if (month < 1 || month > 12) {
      return false;
    }

    // Day validation is already handled by dayjs.isValid()
    return true;
  };

  const handleDobChange = (date: dayjs.Dayjs | null, dateString: string) => {
    if (!dateString) {
      // If dateString is empty, clear the date
      setDob("");
      return;
    }

    if (isValidDate(dateString)) {
      setDob(dayjs(dateString).format("YYYY-MM-DD"));
    } else {
      message.error("Invalid Date. Please use YYYY-MM-DD format.");
    }
  };

  const handleTimeChange = (time: dayjs.Dayjs | null, timeString: string) => {
    if (!timeString) {
      // If timeString is empty, clear the time
      setTime("");
      return;
    }

    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (timeString.match(timeRegex)) {
      setTime(timeString);
    } else {
      message.error("Invalid Time. Please use HH:MM format.");
    }
  };

  const handleGenderChange = (genderString: string) => {
    setGender(genderString);
  };

  const calculateLifeOverview = async (
    dob: string,
    time: string,
    gender: string
  ): Promise<LifeOverviewResult | void> => {
    setIsSubmitting(true);
    try {
      const response = await fetch(cloudFunctionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ dob, time, gender }),
      });
      const data: LifeOverviewData = await response.json();
      console.log(data);
      setIsSubmitting(false);
      return data.result;
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitting(false);
    }
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!dob || !time) {
      message.error({
        content: "Please fill out all fields.",
        style: {
          marginTop: "16vh",
          fontSize: "1rem",
        },
      });
      return;
    }

    const result = await calculateLifeOverview(dob, time, gender);
    if (result) {
      console.log(result);
      setResult(result);
      setFormVisible(false); // Hide the form and button when result is received
    }
  };

  return (
    <div className={styles["form-wrap"]}>
      {/* Conditionally render the form based on the formVisible state */}
      {formVisible && (
        <form
          className={styles["life-overview-form"]}
          id="life-overview-form"
          onSubmit={handleFormSubmit}
        >
          <div className={styles["form-group"]}>
            <label htmlFor="dob">Date of Birth:</label>
            <DatePickerComponent onChange={handleDobChange} />
          </div>
          <div className={styles["form-group"]}>
            <label htmlFor="time">Time of Birth:</label>
            <TimePickerComponent onChange={handleTimeChange} />
          </div>
          <div className={styles["form-group"]}>
            <label htmlFor="gender">Gender:</label>
            <Select
              className={styles["select-gender"]}
              onChange={handleGenderChange}
              placeholder="Select Gender"
              options={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
                { value: "other", label: "Other" },
              ]}
            />
          </div>
          <Button className={styles["submitBtn"]} htmlType="submit">
            {isSubmitting ? "Submitting..." : "Show My Overview"}
          </Button>
        </form>
      )}
      {result && (
        <div className={styles["result"]} id="result">
          {/* Display priority1 if it exists */}
          <p>{result.priority1}</p>

          {/* Ensure priority2 exists and is an array before mapping over it */}
          {Array.isArray(result.priority2) &&
            result.priority2.map((item, index) => <p key={index}>{item}</p>)}
          <Button
            className={styles["startChatBtn"]}
            onClick={() => {
              /* chat start logic here */
            }}
          >
            Start Chat
          </Button>
        </div>
      )}
    </div>
  );
};

export default LifeOverviewForm;
