import React, { useState, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ui } from "../../../config/firebase";
import { uiConfig } from "../../../config/firebaseUIConfig";
import "firebaseui/dist/firebaseui.css";
import styles from "./index.module.scss";

const CustomSignUp: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    ui.start("#firebaseui-auth-container", uiConfig);
    return () => {
      ui.reset(); // Cleanup FirebaseUI instance
    };
  }, []);

  const handleSignUp = async (event: React.FormEvent) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (password.length < 6) {
      setError("Password must be at least 6 characters.");
      return;
    }

    const auth = getAuth();
    setIsSubmitting(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(userCredential.user);
      navigate("/auth/verify-email");
    } catch (error: any) {
      handleAuthError(error);
      setIsSubmitting(false);
    }
  };

  const handleAuthError = (error: any) => {
    switch (error.code) {
      case "auth/email-already-in-use":
        setError("Email already in use.");
        break;
      case "auth/weak-password":
        setError("Password is too weak. Please choose a stronger password.");
        break;
      case "auth/invalid-email":
        setError("Invalid email address.");
        break;
      default:
        setError("An error occurred. Please try again.");
        break;
    }
  };

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
      setError(""); // Clear the error message when user modifies the input field
    };

  return (
    <div className={styles["wrap"]}>
      <h1>Create an account</h1>
      {/* <p>
        Password Requirements: A minimum of 14 characters At least one special
        character At least one numeric character At least one lowercase and
        uppercase letter
      </p> */}
      <div className={styles["custom-signup"]}>
        <form onSubmit={handleSignUp}>
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleInputChange(setEmail)}
            required
            disabled={isSubmitting}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handleInputChange(setPassword)}
            required
            disabled={isSubmitting}
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleInputChange(setConfirmPassword)}
            required
            disabled={isSubmitting}
          />
          {message && <div className={styles["message"]}>{message}</div>}
          {error && <div className={styles["error"]}>{error}</div>}
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Sign Up"}
          </button>
        </form>
        <div className={styles["signin-link"]}>
          Already have an account?{" "}
          <a
            href="/auth/login"
            onClick={(event) => {
              event.preventDefault();
              navigate("/auth/login");
            }}
          >
            Log In
          </a>
        </div>
        <div className={styles["divider"]}>
          <span className={styles["line"]}></span>
          OR
          <span className={styles["line"]}></span>
        </div>
        <div id="firebaseui-auth-container"></div>
      </div>
    </div>
  );
};

export default CustomSignUp;
