import Banner from "../Banner";
import Introduce from "../Introduce";
import Download from "../DownLoad";
import Overview from "../Overview";
import Footer from "../Footer";

const Home = () => {
  return (
    <>
      <Banner />
      <Introduce />
      <Overview />
      <Download />
      {/* TODO: Testmonials */}
      <Footer />
    </>
  );
};

export default Home;
