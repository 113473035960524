import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    const auth = getAuth();
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        setMessage("Password reset email sent! Please check your inbox.");
        setError("");
      } catch (error: any) {
        handleAuthError(error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setError("Please enter your email address to reset your password.");
      setIsSubmitting(false);
    }
  };

  const handleAuthError = (error: any) => {
    switch (error.code) {
      case "auth/invalid-email":
        setError("Invalid email address.");
        break;
      case "auth/user-not-found":
        setError("No user found with this email address.");
        break;
      default:
        setError("An error occurred. Please try again later.");
        break;
    }
    setMessage("");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError(""); // Clear the error message when user modifies the input field
    setMessage(""); // Clear the success message when user modifies the input field
  };

  return (
    <div className={styles["wrap"]}>
      <h1>Reset your password</h1>
      <div className={styles["forgot-password-page"]}>
        <form onSubmit={handlePasswordReset}>
          <p className={styles["instruction"]}>
            Please enter your email address. If there is an account with that
            email, we will send you an email with a link to reset your password.
          </p>
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleInputChange}
            required
            disabled={isSubmitting}
          />
          {error && <div className={styles["error"]}>{error}</div>}
          {message && <div className={styles["message"]}>{message}</div>}
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Sending..." : "Confirm"}
          </button>
        </form>
        <div className={styles["switch-link"]}>
          <a
            href="/auth/login"
            onClick={(event) => {
              event.preventDefault();
              navigate("/auth/login");
            }}
          >
            Back to Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
