// src/components/ProtectedRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import ShimmerWave from "./Loading";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <ShimmerWave />;
  }

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  //   if (user && !user.emailVerified) {
  //     return <Navigate to="/auth/verify-email" />;
  //   }

  return children;
};

export default ProtectedRoute;
