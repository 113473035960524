import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { Menu, MenuProps } from "antd";
import classNames from "classnames";
import styles from "./index.module.scss";

const NavMobile = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const [open, setOpen] = useState<boolean>(false);

  const onClick: MenuProps["onClick"] = async (e) => {
    if (e.key === "logout") {
      await signOut();
      navigate("/auth/login");
    } else {
      navigate(`/${e.key}`);
    }
  };

  const menuItems = user
    ? itemsWithUser(user.email || "Account")
    : itemsWithoutUser;

  return (
    <>
      <div className={styles["menu-item"]} onClick={() => setOpen(!open)}>
        <i
          className={classNames("iconfont", {
            "icon-ego-menu": !open,
            "icon-close": open,
          })}
        />
      </div>
      <Menu
        className={classNames(styles["nav-menu-wrap"], {
          [styles["open"]]: open,
        })}
        onClick={onClick}
        mode="inline"
        theme="dark"
        items={menuItems}
      />
    </>
  );
};

export default NavMobile;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const itemsWithoutUser: MenuItem[] = [
  getItem("Home", ""),
  getItem("About", "about"),
  getItem("Services", "services"),
  getItem("FAQ", "faq"),
  getItem("Privacy Policy", "privacy_policy"),
  getItem("Log In", "auth/login"),
  getItem("Sign Up", "auth/signup"),
];

const itemsWithUser = (email: string): MenuItem[] => [
  getItem("Home", ""),
  getItem("About", "about"),
  getItem("Services", "services"),
  getItem("FAQ", "faq"),
  getItem("Privacy Policy", "privacy_policy"),
  getItem(email, "email", null, [
    getItem("My Plan", "pricing"),
    getItem("Profile", "auth/profile"),
    getItem("Log Out", "logout"),
  ]),
];
