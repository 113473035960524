import { useEffect, useState } from 'react';
import { throttle } from 'lodash-es';

const useScreenWidth = () => {
    const [scrollWidth, setScrollWidth] = useState(window.innerWidth);

    const getScreenWidth = () => setScrollWidth(window.innerWidth);

    useEffect(() => {
        // 节流
        const throttledGetScreenWidth = throttle(getScreenWidth, 100);
        window.addEventListener('resize', throttledGetScreenWidth);
        throttledGetScreenWidth();
        return () =>
            window.removeEventListener('resize', throttledGetScreenWidth);
    }, []);
    return scrollWidth;
};

export default useScreenWidth;
