import styles from './index.module.scss';

interface IItemProps {
    title: string;
    data: string[];
}

const Item = (props: IItemProps) => {
    const { title, data } = props;
    return (
        <div className={styles['wrap']}>
            <ItemTitle title={title} />
            <div className={styles['item-list']}>
                {data?.map((i, index) => (
                    <div className={styles['item']} key={index}>
                        {i}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Item;

interface ITitle {
    title: string;
}

export const ItemTitle = (props: ITitle) => (
    <h1 className={styles['title']}>{props.title}</h1>
);
