import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { ui } from "../../../config/firebase";
import { uiConfig } from "../../../config/firebaseUIConfig";
import "firebaseui/dist/firebaseui.css";
import styles from "./index.module.scss";

const CustomEmailSignIn: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    ui.start("#firebaseui-auth-container", uiConfig);
    return () => {
      ui.reset(); // Cleanup FirebaseUI instance
    };
  }, []);

  const handleSignIn = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    const auth = getAuth();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      if (!user.emailVerified) {
        navigate("/auth/verify-email");
        return;
      }
      // Redirect to the profile page after successful login
      navigate("/auth/profile");
    } catch (error: any) {
      handleAuthError(error);
      setIsSubmitting(false);
    }
  };

  const handleAuthError = (error: any) => {
    switch (error.code) {
      case "auth/invalid-credential":
      case "auth/wrong-password":
        setError("Wrong email or password.");
        break;
      case "auth/too-many-requests":
        setError("Too many requests. Please try again later.");
        break;
      case "auth/invalid-email":
        setError("Invalid email address.");
        break;
      default:
        setError("An error occurred. Please try again.");
        break;
    }
  };

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
      setError(""); // Clear the error message when user modifies the input field
    };

  return (
    <div className={styles["wrap"]}>
      <h1>Login to your account</h1>
      <div className={styles["custom-email-signin"]}>
        <form onSubmit={handleSignIn}>
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleInputChange(setEmail)}
            required
            disabled={isSubmitting}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handleInputChange(setPassword)}
            required
            disabled={isSubmitting}
          />
          {error && <div className={styles["error"]}>{error}</div>}
          <div className={styles["forgot-password-container"]}>
            <a
              href="/auth/forgot-password"
              onClick={(event) => {
                event.preventDefault();
                navigate("/auth/forgot-password");
              }}
              className={styles["forgot-password"]}
            >
              Forgot Password?
            </a>
          </div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Logging in" : "Continue"}
          </button>
        </form>
        <div className={styles["signup-link"]}>
          Don't have an account?{" "}
          <a
            href="/auth/signup"
            onClick={(event) => {
              event.preventDefault();
              navigate("/auth/signup");
            }}
          >
            Sign up
          </a>
        </div>
        <div className={styles["divider"]}>
          <span className={styles["line"]}></span>
          OR
          <span className={styles["line"]}></span>
        </div>
        <div id="firebaseui-auth-container"></div>
      </div>
    </div>
  );
};

export default CustomEmailSignIn;
