// src/firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import * as firebaseui from "firebaseui";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBPWuMcVTO94Az20lG7g5kmoxVtQEI_ZDk",
  authDomain: "chronoscape-feb6d.firebaseapp.com",
  databaseURL:
    "https://chronoscape-feb6d-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "chronoscape-feb6d",
  storageBucket: "chronoscape-feb6d.appspot.com",
  messagingSenderId: "467756591873",
  appId: "1:467756591873:web:8e9ea7973a82ead79e8900",
  measurementId: "G-2DDP71LM1B",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
export const auth = getAuth(app);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize the FirebaseUI Widget using Firebase Auth
export const ui = new firebaseui.auth.AuthUI(auth);
