import useScreenWidth from '../../../hook/useScreenWidth';
import NavMobile from './NavMobile';
import NavPc from './NavPc';

const MOBILE_NAV_SCREEN_WIDTH = 990;

const Nav = () => {
    const screenWidth = useScreenWidth();
    return screenWidth <= MOBILE_NAV_SCREEN_WIDTH ? <NavMobile /> : <NavPc />;
};

export default Nav;
