import React from "react";
import styles from "./index.module.scss";

const ContactPage: React.FC = () => {
  return (
    <div className={styles.aboutPage}>
      <section className={styles.heroSection}>
        <div className={styles.heroContent}>
          <h1>Contact</h1>
          <p>
            Harnessing the power of artificial intelligence to provide
            personalized insights and predictions about your life's journey.
          </p>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;
