// src/components/UpdateBirthDetails/UpdateBirthDetails.tsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../../../config/firebase";
import { message } from "antd";
import styles from "./index.module.scss";

const Profile: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState({
    year: "",
    month: "",
    day: "",
  }); // Default is an empty string
  const [daysInMonth, setDaysInMonth] = useState<string[]>([]);
  const [timeOfBirth, setTimeOfBirth] = useState({
    hour: "",
    minute: "",
    period: "AM",
  }); // Default period is AM
  const [gender, setGender] = useState("");
  const [exactTimeUnknown, setExactTimeUnknown] = useState(false);
  const [lastSubmissionTime, setLastSubmissionTime] = useState<Date | null>(
    null
  ); // State for tracking the last submission time
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setEmail(user.email || ""); // Fetch email from Firebase Auth
        if (!user.emailVerified) {
          navigate("/auth/verify-email");
        }
        const userDoc = doc(db, "users", user.uid); // Reference to the user's document
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setFirstName(userData.firstName);
          setDateOfBirth(userData.dateOfBirth);
          setTimeOfBirth(userData.timeOfBirth);
          setGender(userData.gender);
          setExactTimeUnknown(userData.exactTimeUnknown);
        }
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const getRandomTime = () => {
    const hours = Math.floor(Math.random() * 12) + 1;
    const minutes = Math.floor(Math.random() * 12) * 5;
    const periods = ["AM", "PM"];
    const period = periods[Math.floor(Math.random() * periods.length)];
    return {
      hour: hours.toString().padStart(2, "0"),
      minute: minutes.toString().padStart(2, "0"),
      period: period,
    };
  };

  const handleExactTimeUnknownChange = () => {
    setExactTimeUnknown(!exactTimeUnknown);
    if (!exactTimeUnknown) {
      setTimeOfBirth(getRandomTime());
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      const currentTime = new Date();
      if (
        lastSubmissionTime &&
        currentTime.getTime() - lastSubmissionTime.getTime() < 60000
      ) {
        message.error("Please wait a minute before submitting again.");
        return;
      }
      try {
        const userDoc = doc(db, "users", user.uid); // Reference to the user's document
        await setDoc(userDoc, {
          firstName,
          dateOfBirth,
          timeOfBirth,
          gender,
          exactTimeUnknown,
        });
        setLastSubmissionTime(currentTime); // Update the last submission time
        // Provide feedback to the user
        message.success("Profile updated successfully!");
      } catch (error) {
        console.error("Error updating profile: ", error);
        message.error("Failed to update profile. Please try again.");
      }
    } else {
      message.error("User not authorized. Please log in and try again.");
    }
  };

  const years = Array.from({ length: 100 }, (_, i) =>
    (new Date().getFullYear() - i).toString()
  );

  const months = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];

  const hours = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );

  const minutes = Array.from({ length: 12 }, (_, i) =>
    (i * 5).toString().padStart(2, "0")
  );

  const periods = ["AM", "PM"];

  // Function to determine the number of days in a month
  const getDaysInMonth = (year: string, month: string) => {
    const monthIndex = parseInt(month, 10) - 1;
    const isLeapYear = (year: number) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };
    const daysInMonth = [
      31,
      isLeapYear(parseInt(year)) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
    return Array.from({ length: daysInMonth[monthIndex] }, (_, i) =>
      (i + 1).toString().padStart(2, "0")
    );
  };

  // Update days in month when year or month changes
  useEffect(() => {
    if (dateOfBirth.year && dateOfBirth.month) {
      const newDays = getDaysInMonth(dateOfBirth.year, dateOfBirth.month);
      setDaysInMonth(newDays);
      if (!newDays.includes(dateOfBirth.day)) {
        setDateOfBirth((prev) => ({ ...prev, day: "" }));
      }
    }
  }, [dateOfBirth.year, dateOfBirth.month]);

  return (
    <div className={styles["container"]}>
      <div className={styles["header"]}>
        <h1>My Profile</h1>
      </div>

      <form onSubmit={handleSubmit} className={styles["form"]}>
        <h2>My Profile</h2>
        <div className={styles["form-group"]}>
          <label>
            First Name
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </label>
        </div>
        <div className={styles["form-group"]}>
          <label>
            Email
            <input type="email" value={email} readOnly />
          </label>
        </div>
        <div className={styles["form-group"]}>
          <label>
            Date of Birth
            <div className={styles["date-of-birth"]}>
              <select
                value={dateOfBirth.year}
                onChange={(e) =>
                  setDateOfBirth({ ...dateOfBirth, year: e.target.value })
                }
              >
                <option value="">Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <select
                className={styles["month-select"]}
                value={dateOfBirth.month}
                onChange={(e) =>
                  setDateOfBirth({ ...dateOfBirth, month: e.target.value })
                }
              >
                <option value="">Month</option>
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
              <select
                className={styles["day-select"]}
                value={dateOfBirth.day}
                onChange={(e) =>
                  setDateOfBirth({ ...dateOfBirth, day: e.target.value })
                }
              >
                <option value="">Day</option>
                {daysInMonth.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </div>
          </label>
        </div>
        <div className={styles["form-group"]}>
          <label>
            Time of Birth
            <div className={styles["time-of-birth"]}>
              <select
                className={styles["hour-select"]}
                value={timeOfBirth.hour}
                onChange={(e) =>
                  setTimeOfBirth({ ...timeOfBirth, hour: e.target.value })
                }
                disabled={exactTimeUnknown}
              >
                <option value="">Hour</option>
                {hours.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </select>
              <select
                className={styles["minute-select"]}
                value={timeOfBirth.minute}
                onChange={(e) =>
                  setTimeOfBirth({ ...timeOfBirth, minute: e.target.value })
                }
                disabled={exactTimeUnknown}
              >
                <option value="">Minute</option>
                {minutes.map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </select>
              <select
                className={styles["period-select"]}
                value={timeOfBirth.period}
                onChange={(e) =>
                  setTimeOfBirth({ ...timeOfBirth, period: e.target.value })
                }
                disabled={exactTimeUnknown}
              >
                {periods.map((period) => (
                  <option key={period} value={period}>
                    {period}
                  </option>
                ))}
              </select>
            </div>
          </label>
        </div>
        <div className={styles["exact-time-checkbox"]}>
          <input
            type="checkbox"
            checked={exactTimeUnknown}
            onChange={handleExactTimeUnknownChange}
          />
          <label>I'm not sure about the hour of my birth time</label>
        </div>
        <div className={styles["form-group"]}>
          <label>
            Gender
            <select value={gender} onChange={(e) => setGender(e.target.value)}>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </label>
        </div>
        <button type="submit">Update Profile</button>
      </form>
    </div>
  );
};

export default Profile;
