import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import styles from "./index.module.scss"; // Assuming the styles are defined in a separate CSS module file

interface DatePickerComponentProps {
  onChange: (date: dayjs.Dayjs | null, dateString: string) => void;
}

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  onChange,
}) => {
  return (
    <DatePicker
      className={styles["date-picker"]}
      onChange={onChange}
      showToday={false}
      placeholder="Select Date"
    />
  );
};

export default DatePickerComponent;
