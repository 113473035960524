import React from "react";
import styles from "./index.module.scss";

const AboutPage: React.FC = () => {
  return (
    <div className={styles.aboutPage}>
      <section className={styles.heroSection}>
        <div className={styles.heroContent}>
          <h1>Welcome to FakeLife.AI</h1>
          <p>
            Harnessing the power of artificial intelligence to provide
            personalized insights and predictions about your life's journey.
          </p>
        </div>
      </section>

      <section className={styles.missionSection}>
        <div className={styles.missionContent}>
          <h2>Our Vision</h2>
          <p>
            At FakeLife.AI, we believe that everyone deserves to have a clear
            view of their life path. By blending sophisticated AI technology
            with vast datasets, we aim to deliver meaningful predictions that
            help you navigate your life's milestones with confidence.
          </p>
        </div>
      </section>

      <section className={styles.whatWeDoSection}>
        <div className={styles.whatWeDoContent}>
          <h2>What We Do</h2>
          <p>
            FakeLife.AI utilizes cutting-edge machine learning algorithms to
            analyze your birth details and gender. By comparing these with
            extensive demographic, health, and socio-economic data, our AI can
            forecast significant life events and trends. Whether it's
            understanding your personality traits or predicting potential future
            outcomes, our platform provides insights tailored specifically to
            you.
          </p>
        </div>
      </section>

      <section className={styles.featuresSection}>
        <div className={styles.featuresContent}>
          <h2>Our Features</h2>
          <ul>
            <li>
              <strong>Overview of Personal Traits:</strong> Gain a deeper
              understanding of your personality based on your birth details.
            </li>
            <li>
              <strong>Annual Insights:</strong> Discover what each year might
              hold for you with our detailed yearly predictions.
            </li>
            <li>
              <strong>Matchmaking:</strong> Connect with others based on deep
              compatibility metrics, focusing on core personality traits and
              values.
            </li>
          </ul>
        </div>
      </section>

      <section className={styles.commitmentSection}>
        <div className={styles.commitmentContent}>
          <h2>Our Commitment to Privacy</h2>
          <p>
            We take your privacy seriously. All personal data is anonymized and
            securely processed, ensuring that your information is protected at
            all times.
          </p>
        </div>
      </section>

      <section className={styles.joinUsSection}>
        <div className={styles.joinUsContent}>
          <h2>Join Us on This Journey</h2>
          <p>
            At FakeLife.AI, we're excited to help you explore your life's
            potential. Join us and unlock the insights that can shape your
            future.
          </p>
          <button className={styles.joinButton}>Discover Your Narrative</button>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
