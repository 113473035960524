import { Image } from "antd";

import Item from "./Item";
import { LINKS, SERVICES, SHARE_METHOD_LIST } from "../../constant";

import styles from "./index.module.scss";

const Footer = () => {
  return (
    <div className={styles["wrap"]}>
      <div className={styles["content"]}>
        <div className={styles["main"]}>
          <div className={styles["left-side"]}>
            <div className={styles["item-list"]}>
              <Item title="Our Services" data={SERVICES} />
            </div>
            <div className={styles["item-list"]}>
              <Item title="Quick Links" data={LINKS} />
            </div>
          </div>
          <div className={styles["right-side"]}>
            <Image
              className={styles["logo"]}
              src="https://dashing-licorice-9328bc.netlify.app/icons/logo-notext.svg"
              preview={false}
            />
            <p>
              Discover the pathway to your future with FakeLife.AI --- where
              advanced AI meets the art of life predictions. Navigate your
              life's milestones with personalized insights and connect with the
              world around you on a deeper level. Stay updated and engaged with
              us through our social platforms, and embark on a journey of
              self-discovery and profound connection today.
            </p>

            <div className={styles["share-method"]}>
              {SHARE_METHOD_LIST.map((item, index) => (
                <span key={index} className={styles["share-item"]}>
                  <img
                    src={item.icon}
                    alt={item.key}
                    className={styles["share-icon"]}
                  />{" "}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className={styles["bottom"]}>
          <p>
            © Copyright 2024, All Rights Reserved,
            <span> FakeLife.AI</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
