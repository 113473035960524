import React from "react";
import { TimePicker } from "antd";
import styles from "./index.module.scss";
// import "antd/dist/antd.css"; // Import Ant Design styles
import dayjs from "dayjs";

interface TimePickerComponentProps {
  onChange: (time: dayjs.Dayjs | null, timeString: string) => void;
}

const TimePickerComponent: React.FC<TimePickerComponentProps> = ({
  onChange,
}) => {
  return (
    <TimePicker
      className={styles["time-picker"]}
      onChange={onChange}
      format="HH:mm" // Format for hours and minutes
      showNow={false} // Hide the 'Now' button
      minuteStep={5} // every 5 minutes
      placeholder="Select Time"
    />
  );
};

export default TimePickerComponent;
